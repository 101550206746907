import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Space, Button, Modal, message, Image } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  selectTransfer,
  getTransfers,
  getTransfersSum,
  claimTransfer,
  cancelClaimTransfer,
  // approveTransfer,
  denyTransfer,
  paidTransfer,
  succeededTransfer,
  failedTransfer,
  notifyTransfer,
  cancelTransfer,
  recycleTransfer,
  queryTransfer,
  repaidTransfer,
  exportTransfers,
} from "@/store/slice/transfer";
import { selectAuth } from "@/store/slice/auth";
import { selectApp, getApps } from "@/store/slice/app";
import SumTable from "@/components/SumTable";
import { SearchFormFactory } from "@/components/factory/FormFactory";
import {
  useList,
  useUserPerms,
  useExportExcel,
  useSearchCache,
} from "@/utils/hook";
import { transferStatus, IsBoolEnum } from "@/utils/enum";
import {
  rangeLimitDays,
  objFormateToSearchFields,
  searchFieldsInitialValuesFormat,
  priceFormat,
} from "@/utils/format";
import JsonModal from "@/components/JsonModal";
import Detail from "@/components/Detail";
import { NormalTable } from "@/components/factory/TableFactory";
import EditableConfirm from "@/components/EditableConfirm";
import Paid from "./Paid";
import Repaid from "./Repaid";
import { useSelector } from "react-redux";
import {
  columns as ListColumns,
  detailColumnsCard,
  detailColumnsUSDT,
  sumColumns,
  exportColumnsRenderMap,
} from "./Columns";
import Edit from "./Edit";

const TYPE_ENUMS = {
  failed: "出款失败",
  succeeded: "手动通过",
  // cancelClaim: "退回认领",
};
const createdInitialMoment = [moment().startOf("days"), moment().endOf("day")];
const Transfer = ({ params }) => {
  const perms = useUserPerms();
  const [pageLoading, setPageLoading] = useState(false);
  const { user } = useSelector(selectAuth);
  const searchFields = useMemo(
    () => ({
      id__in: { type: "string", label: "ID" },
      app_user_id__in: { type: "string", label: "商戶用户ID" },
      order_no__in: { type: "string", label: "开发者订单号" },
      ...(perms["app.List"] && {
        app_id__in: {
          type: "searchSelect",
          label: "商户名称",
          action: getApps,
          selector: selectApp,
          searchKey: "name",
          val: "id",
          mode: "multiple",
          optionLabel: i => `${i.id} ${i.name}`,
        },
      }),
      succeeded: {
        type: "select",
        label: "是否成功",
        options: IsBoolEnum,
        isBool: true,
      },
      gateway_name__k: { type: "string", label: "网关名称" },
      is_online: {
        type: "select",
        label: "是否在线订单",
        options: IsBoolEnum,
      },
      status: { type: "select", label: "订单状态", options: transferStatus },
      created__btw: {
        type: "rangeDate",
        label: "创建时间",
        initialValue: createdInitialMoment,
      },
      ...objFormateToSearchFields(params),
    }),
    [params, perms],
  );
  const fieldsInitialValues = useMemo(() => {
    return searchFieldsInitialValuesFormat(searchFields);
  }, [searchFields]);
  const { handleGetPageSearchCache } = useSearchCache();

  const {
    res: { list, meta, sum },
    loading: listLoading,
    searchParams,
    handleSearch,
    handleGetList,
    handleChangePage,
    handleChange,
  } = useList(getTransfers, selectTransfer, { ...fieldsInitialValues });
  const dispatch = useDispatch();
  const handleGetTransfersSum = (search = {}) => {
    dispatch(
      getTransfersSum({
        ...fieldsInitialValues,
        ...handleGetPageSearchCache(),
        ...search,
      }),
    );
  };
  useEffect(() => {
    handleGetTransfersSum();
    // eslint-disable-next-line
  }, []);

  const handleCustomSearch = formModel => {
    if (!formModel.created__btw) {
      message.warning("「创建时间」必填");
      return;
    }
    if (!rangeLimitDays(31, formModel.created__btw)) {
      message.warning("「创建时间」不得超過31天");
      return;
    }
    const searchParams = {
      ...formModel,
      ...(formModel.succeeded === false && { status__in: "10,13,14,15,16,17" }),
    };
    handleSearch({ ...searchParams });
    handleGetTransfersSum(searchParams);
  };

  /**
   * 匯出
   */
  const {
    loading: exportLoading,
    handleExport,
    handleSelectedColumns,
  } = useExportExcel({
    exportColumnsRenderMap,
    exportAction: exportTransfers,
  });

  const refetchPageData = async () => {
    handleGetList();
    handleGetTransfersSum(searchParams);
  };
  const [jsonVisible, setJsonVisible] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const handleJsonClick = record => {
    setCurrentRow(record);
    setJsonVisible(true);
  };

  const [detailVisible, setDetailVisible] = useState(false);
  const handleDetailClick = record => {
    setCurrentRow(record);
    setDetailVisible(true);
  };

  const handleClaimClick = record => {
    Modal.confirm({
      title: "是否认领",
      icon: <ExclamationCircleOutlined />,
      content: `即将认领 ${record.id}，是否继续？`,
      okText: "确认",
      cancelText: "取消",
      onOk: close => handleClaim(close, record),
    });
  };
  const handleClaim = async (close, record) => {
    const { status } = await claimTransfer({
      id: record.id,
      formModel: { paid_id: user.id },
    });
    close();
    if (status !== 200) return;
    refetchPageData();
  };

  const [paidVisible, setPaidVisible] = useState(false);
  const [paidLoading, setPaidLoading] = useState(false);
  const handlePaidClick = record => {
    setCurrentRow(record);
    setPaidVisible(true);
  };
  const handlePaid = async formModel => {
    setPaidLoading(true);
    const { status } = await paidTransfer({
      id: currentRow.id,
      formModel: { ...formModel, paid_id: user.id },
    });
    setPaidLoading(false);
    if (status !== 200) return;
    setPaidVisible(false);
    message.success("已出款!");
    refetchPageData();
  };

  const [editLoading, setEditLoading] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [editConfirmVisible, setEditConfirmVisible] = useState(false);
  const [editMode, setEditMode] = useState("approve");
  const fields =
    editMode === "failed"
      ? [
          {
            label: "错误码",
            name: "failure_code",
            inputType: "string",
          },
          {
            label: "失败原因",
            name: "failure_msg",
            inputType: "string",
            required: true,
          },
        ]
      : // : editMode === "cancelClaim"
        // ? [
        //     {
        //       label: "备注",
        //       name: "note",
        //       inputType: "string",
        //     },
        //   ]
        [
          {
            label: "备注",
            name: "comments",
            inputType: "string",
          },
        ];
  const handleEditClick = (record, mode) => {
    setCurrentRow(record);
    setEditMode(mode);
    mode === "succeeded" ? setEditVisible(true) : setEditConfirmVisible(true);
  };
  const handleEdit = async formModel => {
    setEditLoading(true);
    const { status } =
      editMode === "succeeded"
        ? await succeededTransfer({
            id: currentRow.id,
            formModel: { ...formModel, paid_id: user.id },
          })
        : // : editMode === "approve"
        // ? await approveTransfer({
        //     id: currentRow.id,
        //     formModel: { ...formModel, approver_id: user.id },
        //   })
        // editMode === "cancelClaim"
        // ? await cancelClaimTransfer({
        //     id: currentRow.id,
        //     formModel: { ...formModel },
        //   }) :
        editMode === "deny"
        ? await denyTransfer({
            id: currentRow.id,
            formModel: { ...formModel, approver_id: user.id },
          })
        : await failedTransfer({
            id: currentRow.id,
            formModel,
          });
    setEditLoading(false);
    if (status !== 200) return;
    setEditConfirmVisible(false);
    setEditVisible(false);
    message.success(`已${TYPE_ENUMS[editMode]}`);
    refetchPageData();
  };
  // const handleSucceededClick = async record => {
  //   Modal.confirm({
  //     title: "是否出款成功",
  //     icon: <ExclamationCircleOutlined />,
  //     content: `即将出款成功 ${record.id}，是否继续？`,
  //     okText: "确认",
  //     cancelText: "取消",
  //     onOk: close => handleSucceeded(close, record),
  //   });
  // };
  // const handleSucceeded = async (close, record) => {
  //   const { status } = await succeededTransfer({
  //     id: record.id,
  //     formModel: { paid_id: user.id },
  //   });
  //   close();
  //   if (status !== 200) return;
  //   handleGetList(params);
  // };
  const handleCancelClaimTransferClick = id => {
    Modal.confirm({
      title: "是否退回认领",
      icon: <ExclamationCircleOutlined />,
      content: `即将退回订单 ${id}，是否继续？`,
      okText: "确认",
      cancelText: "取消",
      onOk: close => handleCancelClaimTransfer(close, id),
    });
  };
  const handleCancelClaimTransfer = async (close, id) => {
    const { status } = await cancelClaimTransfer({ id });
    close();
    if (status !== 200) return;
    refetchPageData();
  };
  const handleNotifyClick = record => {
    Modal.confirm({
      title: "是否手动回调",
      icon: <ExclamationCircleOutlined />,
      content: `即将手动回调 ${record.id}，是否继续？`,
      okText: "确认",
      cancelText: "取消",
      onOk: close => handleNotify(close, record),
    });
  };
  const handleNotify = async (close, record) => {
    const { status } = await notifyTransfer({ id: record.id });
    close();
    if (status !== 200) return;
    refetchPageData();
  };
  const handleCancelClick = record => {
    Modal.confirm({
      title: "是否取消认领",
      icon: <ExclamationCircleOutlined />,
      content: `即将取消认领 ${record.id}，是否继续？`,
      okText: "确认",
      cancelText: "取消",
      onOk: close => handleCancel(close, record),
    });
  };
  const handleCancel = async (close, record) => {
    const { status } = await cancelTransfer({ id: record.id });
    close();
    if (status !== 200) return;
    refetchPageData();
  };

  const handleRecycleClick = id => {
    Modal.confirm({
      title: "是否收回订单",
      icon: <ExclamationCircleOutlined />,
      content: `即将收回订单 ${id}，是否继续？`,
      okText: "确认",
      cancelText: "取消",
      onOk: close => handleRecycleTransfer(close, id),
    });
  };
  const handleRecycleTransfer = async (close, id) => {
    const { status } = await recycleTransfer(id);
    close();
    if (status !== 200) return;
    message.success("訂單已收回！");
    refetchPageData();
  };

  const handleQuery = async record => {
    setPageLoading(true);
    const { status, data } = await queryTransfer(record.id);
    setPageLoading(false);
    if (status !== 200) return;
    handleJsonClick(data);
  };
  const [repaidVisible, setRepaidVisible] = useState(false);
  const [repaidLoading, setRepaidLoading] = useState(false);
  const handleRepaidClick = record => {
    setCurrentRow(record);
    setRepaidVisible(true);
  };
  const handleRepaid = async formModel => {
    setRepaidLoading(true);
    const { status } = await repaidTransfer({
      id: currentRow.id,
      formModel: { ...formModel, paid_id: user.id },
    });
    setRepaidLoading(false);
    if (status !== 200) return;
    setRepaidVisible(false);
    // setEditVisible(false);
    message.success("已重新出款!");
    refetchPageData();
  };

  function copyTransferInfo() {
    const { name, account, bank_name, amount, currency } = currentRow;
    const val = `姓名：${name}\n银行卡号：${account}\n开户行：${bank_name}\n提款金额：${priceFormat(
      { val: amount, currency },
    )}`;
    navigator.clipboard.writeText(val);
    message.success("复制成功");
  }

  const [visiblePreview, setVisiblePreview] = useState(false);
  const [previewUrls, setPreviewUrls] = useState([]);
  const previewApproveImages = urls => {
    setVisiblePreview(true);
    setPreviewUrls(urls);
  };

  const columns = [
    ...ListColumns,
    {
      title: "动作",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => handleJsonClick(record)}
            type="link"
            className="p-0"
            disabled={!perms["transfer.Get"]}
          >
            json
          </Button>
          <Button
            size="small"
            onClick={() => handleDetailClick(record)}
            type="link"
            className="p-0"
            disabled={!perms["transfer.Get"]}
          >
            查看
          </Button>
          {!Boolean(params) && record.extra?.approve_image_urls && (
            <Button
              size="small"
              type="text"
              className="p-0"
              onClick={() =>
                previewApproveImages(record.extra.approve_image_urls)
              }
            >
              审核凭证
            </Button>
          )}
          {!params && record.status === 16 && (
            <Button
              size="small"
              type="text"
              className="p-0"
              onClick={() => handleNotifyClick(record)}
              disabled={!perms["transfer.Notify"]}
            >
              回调
            </Button>
          )}
          {[9, 15, 16].includes(record.transfer_status) && (
            <>
              <Button
                size="small"
                onClick={() => handleRecycleClick(record.id)}
                type="text"
                className="p-0"
                disabled={!perms["transfer.Recycle"] || record.is_recycle}
              >
                {record.is_recycle ? "已收回" : "收回"}
              </Button>
            </>
          )}
          {record.status === 19 && (
            <>
              <Button
                size="small"
                type="text"
                className="p-0"
                onClick={() => handleEditClick(record, "succeeded")}
                disabled={!perms["transfer.Succeeded"]}
              >
                手動通過
              </Button>
              <Button
                size="small"
                type="text"
                className="p-0"
                onClick={() => handleCancelClaimTransferClick(record.id)}
                disabled={!perms["transfer.CancelClaim"]}
              >
                退回认领
              </Button>
            </>
            //   <Button
            //   size="small"
            //   type="text"
            //   className="p-0"
            //   onClick={() => handleSucceededClick(record)}
            // >
            //   出款成功
            // </Button>
          )}
          {params?.status === 2 && (
            <Button
              size="small"
              type="text"
              className="p-0"
              onClick={() => handleClaimClick(record)}
              disabled={!perms["transfer.PaidClaim"]}
            >
              认领
            </Button>
          )}
          {params?.status === 7 && (
            <>
              <Button
                size="small"
                type="text"
                className="p-0"
                onClick={() => handlePaidClick(record)}
                disabled={!perms["transfer.Paid"] || !perms["transfer.Gateway"]}
              >
                出款
              </Button>
              <Button
                size="small"
                type="text"
                className="p-0"
                onClick={() => handleCancelClick(record)}
                disabled={!perms["transfer.PaidCancel"]}
              >
                取消认领
              </Button>
            </>
          )}
          {(params?.status__in === "8,18" ||
            [8, 19, 20].includes(record.status)) && (
            <Button
              size="small"
              type="text"
              className="p-0"
              onClick={() => handleEditClick(record, "failed")}
              disabled={!perms["transfer.Failed"]}
            >
              {TYPE_ENUMS.failed}
            </Button>
          )}
          {params?.status__in === "8,18" && (
            <Button
              size="small"
              type="text"
              className="p-0"
              onClick={() => handleQuery(record)}
              disabled={!perms["transfer.Query"]}
            >
              查询
            </Button>
          )}
          {params?.status__in === "8,18" && (
            <Button
              size="small"
              type="text"
              className="p-0"
              onClick={() => handleRepaidClick(record)}
              disabled={
                !perms["transfer.RePaid"] || !perms["transfer.OfflineGateway"]
              }
            >
              重新出款
            </Button>
          )}
          {params?.status__in === "8,18" && (
            <Button
              size="small"
              type="text"
              className="p-0"
              onClick={() => handleEditClick(record, "succeeded")}
              disabled={!perms["transfer.Succeeded"]}
            >
              手動通過
            </Button>
          )}
        </Space>
      ),
    },
  ];
  const defaultColumns = [
    "id",
    "order_no",
    "app_name_cn",
    "amount",
    "amount_paid",
    "note",
    "gateway_name",
    "created",
    "paid_name",
    "paid_at",
    "succeeded",
    "succeeded_at",
    "status",
    "action",
  ];

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <SearchFormFactory
        fields={searchFields}
        handleSubmit={handleCustomSearch}
        showExportBtn={!params}
        handleExport={handleExport}
        exportLoading={exportLoading}
      />
      <SumTable data={sum} labels={sumColumns} />
      <NormalTable
        allColumns={columns}
        defaultColumns={defaultColumns}
        dataSource={list}
        meta={meta}
        onChangePage={handleChangePage}
        onChange={handleChange}
        loading={listLoading || pageLoading}
        onShowSizeChange={handleChangePage}
        setSelectedColumns={handleSelectedColumns}
      />
      <JsonModal
        width={650}
        visible={jsonVisible}
        data={currentRow}
        onCancel={() => setJsonVisible(false)}
        loading={false}
      />
      <Detail
        title="代付明細"
        visible={detailVisible}
        data={currentRow}
        onCancel={() => setDetailVisible(false)}
        loading={false}
        footer={[
          <Button key="close" type="primary" ghost onClick={copyTransferInfo}>
            复制提款信息
          </Button>,
        ]}
        columns={
          currentRow.currency === 0 ? detailColumnsCard : detailColumnsUSDT
        }
      />
      <EditableConfirm
        title={TYPE_ENUMS[editMode]}
        fields={fields}
        visible={editConfirmVisible}
        data={currentRow}
        onCancel={() => setEditConfirmVisible(false)}
        loading={editLoading}
        onOk={handleEdit}
      />
      <Paid
        visible={paidVisible}
        data={currentRow}
        onCancel={() => setPaidVisible(false)}
        loading={paidLoading}
        onOk={handlePaid}
      />
      <Repaid
        visible={repaidVisible}
        data={currentRow}
        onCancel={() => setRepaidVisible(false)}
        loading={repaidLoading}
        onOk={handleRepaid}
      />
      {/* succeeded 手動通過 彈窗 */}
      <Edit
        visible={editVisible}
        onOk={handleEdit}
        onCancel={() => setEditVisible(false)}
        loading={editLoading}
        data={currentRow}
        mode={editMode}
      />
      <Image.PreviewGroup
        preview={{
          visible: visiblePreview,
          onVisibleChange: vis => setVisiblePreview(vis),
        }}
      >
        {previewUrls.map((url, idx) => (
          <Image key={idx} width={200} style={{ display: "none" }} src={url} />
        ))}
      </Image.PreviewGroup>
    </Space>
  );
};
export default Transfer;
